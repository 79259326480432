var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "lista-proposta" },
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 px-0 proposta" },
        [
          _c("breadcrumb", {
            attrs: { title: "Propostas", actualPage: "Lista de propostas" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-3 mt-8 mb-6 gap-3 w-full md:w-4/5 lg:w-3/5 lp:w-1/2",
        },
        [
          _c(
            "filtro",
            {
              ref: "filtro",
              staticClass: "pesquisa",
              attrs: { titleName: "Propostas", isFilter: _vm.filtro },
            },
            [
              _c(
                "div",
                { staticClass: "pt-2 pb-6 px-4" },
                [
                  _c(
                    "vs-row",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": "6", "vs-sm": "12" } },
                        [
                          _c("vs-input", {
                            attrs: {
                              size: "large",
                              label: "Número da proposta",
                            },
                            model: {
                              value: _vm.filterValue.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterValue, "number", $$v)
                              },
                              expression: "filterValue.number",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          staticClass: "container-element pt-6",
                          attrs: { "vs-lg": "6", "vs-sm": "12" },
                        },
                        [
                          _c("p", { staticClass: "label-content label" }, [
                            _vm._v("Status da proposta"),
                          ]),
                          _c("v-select", {
                            staticClass: "w-full",
                            attrs: {
                              placeholder: "Selecionar",
                              "show-no-options": false,
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                              label: "name",
                              options: _vm.proposalStatusOptionsInsurerMixin,
                              size: "large",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setProposalStatusOption(
                                  _vm.selectedProposalStatusOption
                                )
                              },
                            },
                            model: {
                              value: _vm.selectedProposalStatusOption,
                              callback: function ($$v) {
                                _vm.selectedProposalStatusOption = $$v
                              },
                              expression: "selectedProposalStatusOption",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          staticClass: "container-element pt-6",
                          attrs: { "vs-lg": "6", "vs-sm": "12" },
                        },
                        [
                          _c("p", { staticClass: "label-content label" }, [
                            _vm._v("Data inicial"),
                          ]),
                          _c("datepicker", {
                            attrs: {
                              language: _vm.language,
                              format: "dd/MM/yyyy",
                            },
                            model: {
                              value: _vm.filterValue.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterValue, "startDate", $$v)
                              },
                              expression: "filterValue.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          staticClass: "container-element pt-6",
                          attrs: { "vs-lg": "6", "vs-sm": "12" },
                        },
                        [
                          _c("p", { staticClass: "label-content label" }, [
                            _vm._v("Data final"),
                          ]),
                          _c("datepicker", {
                            attrs: {
                              language: _vm.language,
                              format: "dd/MM/yyyy",
                            },
                            model: {
                              value: _vm.filterValue.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterValue, "endDate", $$v)
                              },
                              expression: "filterValue.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          staticClass: "container-element pt-6",
                          attrs: { "vs-lg": "6", "vs-sm": "12" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "label-content label",
                              staticStyle: { "margin-left": "-12px" },
                            },
                            [
                              _vm._v(
                                "\n              Tipo de proposta:\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "vs-col",
                        {
                          staticClass: "container-element pt-6",
                          attrs: { "vs-lg": "6", "vs-sm": "12" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "label-content label",
                              staticStyle: { "margin-left": "-12px" },
                            },
                            [
                              _vm._v(
                                "\n              Tipo endosso:\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "vs-col",
                        {
                          staticClass: "flex justify-between pt-2",
                          attrs: { "vs-lg": "6", size: "small" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Selecione" },
                              on: {
                                change: function ($event) {
                                  return _vm.typeChange()
                                },
                              },
                              model: {
                                value: _vm.filterValue.proposalType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterValue, "proposalType", $$v)
                                },
                                expression: "filterValue.proposalType",
                              },
                            },
                            _vm._l(_vm.typesProposal, function (item) {
                              return _c("el-option", {
                                key: item.Id,
                                attrs: { label: item.label, value: item.Id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          staticClass: "flex justify-between pt-2",
                          attrs: { "vs-lg": "6", size: "small" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Selecione" },
                              on: {
                                change: function ($event) {
                                  return _vm.typeChangeEndosso()
                                },
                              },
                              model: {
                                value: _vm.filterValue.endossoType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterValue, "endossoType", $$v)
                                },
                                expression: "filterValue.endossoType",
                              },
                            },
                            _vm._l(_vm.subtiposEndosso, function (item) {
                              return _c("el-option", {
                                key: item.Id,
                                attrs: { label: item.Name, value: item.Id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "mt-6 opacity-25" }),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                        [
                          _c("vs-input", {
                            attrs: { size: "large", label: "Nome do tomador" },
                            model: {
                              value: _vm.filterValue.policyHolderName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filterValue,
                                  "policyHolderName",
                                  $$v
                                )
                              },
                              expression: "filterValue.policyHolderName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                        [
                          _c("vs-input", {
                            attrs: {
                              size: "large",
                              label: "Documento do tomador",
                            },
                            model: {
                              value: _vm.filterValue.policyHolderCpfCnpj,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filterValue,
                                  "policyHolderCpfCnpj",
                                  $$v
                                )
                              },
                              expression: "filterValue.policyHolderCpfCnpj",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                        [
                          _c("vs-input", {
                            attrs: { size: "large", label: "Nome do corretor" },
                            model: {
                              value: _vm.filterValue.brokerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterValue, "brokerName", $$v)
                              },
                              expression: "filterValue.brokerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "mt-6 opacity-25" }),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        { staticClass: "flex justify-between" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              model: {
                                value: _vm.filterValue.enableToBigRisk,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filterValue,
                                    "enableToBigRisk",
                                    $$v
                                  )
                                },
                                expression: "filterValue.enableToBigRisk",
                              },
                            },
                            [_vm._v("Grande risco\n            ")]
                          ),
                          _c(
                            "vs-checkbox",
                            {
                              model: {
                                value: _vm.filterValue.enableToMonitoring,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filterValue,
                                    "enableToMonitoring",
                                    $$v
                                  )
                                },
                                expression: "filterValue.enableToMonitoring",
                              },
                            },
                            [_vm._v("Monitoramento\n            ")]
                          ),
                          _c(
                            "vs-checkbox",
                            {
                              model: {
                                value: _vm.filterValue.enableToSteppin,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filterValue,
                                    "enableToSteppin",
                                    $$v
                                  )
                                },
                                expression: "filterValue.enableToSteppin",
                              },
                            },
                            [_vm._v("Retoma de objeto (Step-in)\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex flex-row-reverse mt-4 pt-4" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4",
                          attrs: { color: "primary", type: "filled" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.filterProposals(true)
                            },
                          },
                        },
                        [_vm._v("Aplicar filtro")]
                      ),
                      _c(
                        "vs-button",
                        {
                          attrs: {
                            color: "primary",
                            type: "filled",
                            icon: "clear",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.clearFilter()
                            },
                          },
                        },
                        [_vm._v("Limpar filtro")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content pt-2" },
        [
          _vm.proposals.length > 0
            ? [
                _c(
                  "vs-table",
                  {
                    staticClass: "table-list",
                    attrs: {
                      data: _vm.proposals || [],
                      noDataText: "Nenhuma proposta encontrada",
                      hoverFlat: true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ data }) {
                            return _vm._l(data, function (tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr, attrs: { data: tr } },
                                [
                                  _c(
                                    "vs-td",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass: "btn-action-proposta",
                                          attrs: {
                                            "vs-custom-content": "",
                                            "vs-trigger-click": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "dark",
                                                type: "flat",
                                                title: "Ações",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                attrs: { role: "button" },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            {
                                              staticClass:
                                                "btn-action-proposta-options w-2/5 md:w-1/3 lg:w-1/4 xl:w-1/5",
                                            },
                                            [
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goToProposal(
                                                        data[indextr]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-base px-6 py-2",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Visualizar proposta"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  attrs: { divider: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.downloadMinuteClick(
                                                        data[indextr].UniqueId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-base px-6 py-2",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            data[indextr]
                                                              .StatusId == 1
                                                              ? "Imprimir proposta"
                                                              : "Imprimir minuta"
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              data[indextr]
                                                .PolicyEndorsementReasonBaseId ==
                                              18
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.disclamerCancel(
                                                            data[indextr]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Cancelar Endosso"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              data[indextr].TypeName ===
                                                "Renovação" &&
                                              _vm.canCancelItem(
                                                data[indextr].StatusId
                                              )
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cancelarRenovacao(
                                                            data[indextr]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Cancelar proposta"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              data[indextr]
                                                .EnableManualPolicyEmission ||
                                              data[indextr]
                                                .EnableManualBilletEmission
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.goToEndosso(
                                                            data[indextr]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Emitir endosso manual"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: { data: data[indextr].Number },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex" },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(data[indextr].Number)
                                            ),
                                          ]),
                                          data[indextr].StatusId === 6
                                            ? _c(
                                                "vx-tooltip",
                                                {
                                                  attrs: {
                                                    text: "Proposta ainda não se encontra para análise da seguradora. Verifique as pendências para seguir com o processo de emissão",
                                                    position: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons alert text-xl ml-1 warning",
                                                      staticStyle: {},
                                                    },
                                                    [_vm._v("info")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: { data: data[indextr].TypeId },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(_vm._s(data[indextr].TypeName)),
                                      ]),
                                    ]
                                  ),
                                  _vm.podeExibirCanal
                                    ? _c(
                                        "vs-td",
                                        {
                                          staticClass: "text-center w-1/4",
                                          attrs: {
                                            data: data[indextr].NomeTipoCanal,
                                          },
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                data[indextr].NomeTipoCanal
                                                  ? data[indextr].NomeTipoCanal
                                                  : "Canal não definido"
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/5",
                                      attrs: {
                                        data: data[indextr].PolicyHolderCpfCnpj,
                                      },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(data[indextr].PolicyHolderName)
                                        ),
                                      ]),
                                      _c("small", { staticClass: "block" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("maskCpfCnpj")(
                                                data[indextr]
                                                  .PolicyHolderCpfCnpj
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: {
                                        data: data[indextr].ModalityName,
                                      },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(data[indextr].ModalityName)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass:
                                        "text-center w-1/12 date_color",
                                      attrs: { data: data[indextr].CreateDate },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              new Date(
                                                data[indextr].CreateDate
                                              ),
                                              "DD/MM/YYYY"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: {
                                        data: data[indextr].InsuredAmountValue,
                                      },
                                    },
                                    [
                                      _c("currency-input", {
                                        staticClass:
                                          "input-currency font-semibold text-center",
                                        attrs: { disabled: "true" },
                                        model: {
                                          value:
                                            data[indextr].InsuredAmountValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              data[indextr],
                                              "InsuredAmountValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data[indextr].InsuredAmountValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: {
                                        data: data[indextr].InsurancePremium,
                                      },
                                    },
                                    [
                                      _c("currency-input", {
                                        staticClass:
                                          "input-currency font-semibold text-center",
                                        attrs: { disabled: "true" },
                                        model: {
                                          value: data[indextr].InsurancePremium,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              data[indextr],
                                              "InsurancePremium",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data[indextr].InsurancePremium",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: { data: data[indextr].StatusName },
                                    },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          staticClass: "status",
                                          attrs: {
                                            type: "filled",
                                            size: "small",
                                            color: _vm._f("setColorStatus")(
                                              data[indextr].StatusId,
                                              data[indextr].TimelineStatusId
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("setLabelStatus")(
                                                  data[indextr].StatusId
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: { data: data[indextr].BrokerName },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(data[indextr].BrokerName)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      2920561660
                    ),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th"),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "Number" },
                          },
                          [_vm._v("Proposta nº")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "TypeName" },
                          },
                          [_vm._v("Tipo")]
                        ),
                        _vm.podeExibirCanal
                          ? _c(
                              "vs-th",
                              {
                                staticClass: "text-center",
                                attrs: { "sort-key": "Channel" },
                              },
                              [_vm._v("Canal")]
                            )
                          : _vm._e(),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "PolicyHolderName" },
                          },
                          [_vm._v("Tomador")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "ModalityName" },
                          },
                          [_vm._v("Modalidade")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "dataProposta" },
                          },
                          [_vm._v("Data da proposta")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "is" },
                          },
                          [_vm._v("IS")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "valorPremio" },
                          },
                          [_vm._v("Valor Prêmio")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "status" },
                          },
                          [_vm._v("Status")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "broker" },
                          },
                          [_vm._v("Corretor")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("vs-pagination", {
                  staticClass: "mt-5",
                  attrs: { total: _vm.numberOfPages },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                }),
              ]
            : [_vm._m(0)],
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "termos-modal fix-scroll",
          attrs: {
            "button-close-hidden": true,
            title:
              "Endosso manual proposta N°: " + _vm.uploadData.ProposalNumber,
            active: _vm.modalEndosso,
          },
          on: {
            "update:active": function ($event) {
              _vm.modalEndosso = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticClass: "text-center",
                  attrs: { label: "Dados Gerais", name: "first" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "el-alert el-alert--warning is-light",
                      attrs: { role: "alert" },
                    },
                    [
                      _c("div", { staticClass: "el-alert__content" }, [
                        _c("p", { staticClass: "el-alert__description" }, [
                          _vm._v(
                            "\n              Para prosseguir com a emissão do endosso manual, será necessário\n              incluir os documentos do endosso e os boletos correspondentes.\n              Certifique-se de que todos os documentos e informações\n              necessários estejam corretos e completos antes de dar\n              continuidade ao processo.\n              "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.activeName = "second"
                                },
                              },
                            },
                            [_vm._v("Prosseguir")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("br"),
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.disclamerforcarAprovManual()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                        _vm._v(
                          "\n            Forçar Emitir Endosso\n          "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": 6, "vs-sm": "12" } },
                        [
                          _c("p", { staticClass: "flex font-semibold" }, [
                            _vm._v("N° Documento Segurado"),
                          ]),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { disabled: "", size: "large" },
                            model: {
                              value: _vm.uploadData.InsuredCpfCnpj,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploadData, "InsuredCpfCnpj", $$v)
                              },
                              expression: "uploadData.InsuredCpfCnpj",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": 6, "vs-sm": "12" } },
                        [
                          _c("p", { staticClass: "flex font-semibold" }, [
                            _vm._v("Segurado"),
                          ]),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { disabled: "", size: "large" },
                            model: {
                              value: _vm.uploadData.ProposalNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploadData, "ProposalNumber", $$v)
                              },
                              expression: "uploadData.ProposalNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": 6, "vs-sm": "12" } },
                        [
                          _c("p", { staticClass: "flex font-semibold" }, [
                            _vm._v("N° Documento Tomador"),
                          ]),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { disabled: "", size: "large" },
                            model: {
                              value: _vm.uploadData.PolicyHolderCpfCnpj,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.uploadData,
                                  "PolicyHolderCpfCnpj",
                                  $$v
                                )
                              },
                              expression: "uploadData.PolicyHolderCpfCnpj",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": 6, "vs-sm": "12" } },
                        [
                          _c("p", { staticClass: "flex font-semibold" }, [
                            _vm._v("Tomador"),
                          ]),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { disabled: "", size: "large" },
                            model: {
                              value: _vm.uploadData.PolicyHolderName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.uploadData,
                                  "PolicyHolderName",
                                  $$v
                                )
                              },
                              expression: "uploadData.PolicyHolderName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vw-row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full text-right" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "button-default mr-5",
                            on: {
                              click: function ($event) {
                                _vm.modalEndosso = false
                              },
                            },
                          },
                          [_vm._v("\n              Cancelar\n            ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Upload Endosso", name: "second" } },
                [
                  _vm.uploadData.IsPolicyDocumentSent
                    ? _c(
                        "div",
                        { staticStyle: { margin: "20px 0px" } },
                        [
                          _c("el-alert", {
                            attrs: {
                              title: "Apólice enviada com sucesso!",
                              type: "success",
                              closable: false,
                              "show-icon": "",
                            },
                          }),
                          _c("div", { staticClass: "vw-row mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full text-right" },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "button-default mr-5",
                                    staticStyle: { "margin-right": "5px" },
                                    on: {
                                      click: function ($event) {
                                        _vm.modalEndosso = false
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Cancelar\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c("div", [
                        _c("div", { staticClass: "con-img-upload" }, [
                          _vm.apolice.base64
                            ? _c("div", { staticClass: "img-upload" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-x-file",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFileApolice()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons notranslate",
                                        attrs: { translate: "no" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  clear\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "h4",
                                  {
                                    staticClass: "text-archive",
                                    staticStyle: {
                                      width: "100%",
                                      "font-size": "12px",
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons notranslate",
                                        attrs: { translate: "no" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  description\n                "
                                        ),
                                      ]
                                    ),
                                    _c("p", [
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.apolice.Filename)),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ])
                            : _c("div", { staticClass: "con-input-upload" }, [
                                _c("input", {
                                  attrs: {
                                    "data-v-480def7c": "",
                                    type: "file",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getFilesApolice($event)
                                    },
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-input",
                                    attrs: { "data-v-480def7c": "" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Clique aqui para selecionar o arquivo. "
                                    ),
                                  ]
                                ),
                                _c("span", {
                                  staticClass: "input-progress",
                                  staticStyle: { width: "0%" },
                                  attrs: { "data-v-480def7c": "" },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-upload-all vs-upload--button-upload",
                                    attrs: {
                                      "data-v-480def7c": "",
                                      disabled: "disabled",
                                      type: "button",
                                      title: "Upload",
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons notranslate",
                                        attrs: {
                                          "data-v-480def7c": "",
                                          translate: "translate",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  cloud_upload\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                        ]),
                        _c("div", { staticClass: "vw-row mt-3" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full text-right" },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "button-default mr-5",
                                  staticStyle: { "margin-right": "5px" },
                                  on: {
                                    click: function ($event) {
                                      _vm.modalEndosso = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Cancelar\n              "
                                  ),
                                ]
                              ),
                              _vm.apolice.base64
                                ? _c(
                                    "vs-button",
                                    {
                                      attrs: { color: "primary" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.disclamerEnviarApolice()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-semibold pr-2 pl-2",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Enviar endosso\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "vs-button",
                                    {
                                      attrs: { disabled: "", color: "primary" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-semibold pr-2 pl-2",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Enviar endosso\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                ]
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Upload Boletos", name: "third" } },
                [
                  _vm.uploadData.IsInstallmentsSent
                    ? _c(
                        "div",
                        { staticStyle: { margin: "20px 0px" } },
                        [
                          _vm.uploadData.IsInstallmentsSent &&
                          _vm.uploadData.Installments.length > 0
                            ? _c("el-alert", {
                                attrs: {
                                  title: "Boletos enviados com sucesso!",
                                  type: "success",
                                  closable: false,
                                  "show-icon": "",
                                },
                              })
                            : _c("div", { staticClass: "text-center" }, [
                                _c("h4", [
                                  _vm._v(
                                    "Nenhum Boleto disponível no momento."
                                  ),
                                ]),
                              ]),
                          _c("div", { staticClass: "vw-row mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full text-right" },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "button-default mr-5",
                                    staticStyle: { "margin-right": "5px" },
                                    on: {
                                      click: function ($event) {
                                        _vm.modalEndosso = false
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Cancelar\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c("div", [
                        _vm.uploadData.IsPolicyDocumentSent == false
                          ? _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c("el-alert", {
                                  attrs: {
                                    title: "Atenção!",
                                    type: "warning",
                                    description:
                                      "Para Prosseguir com envio dos boletos é necessário o envio da apólice previamente!",
                                  },
                                }),
                                _c(
                                  "vs-button",
                                  {
                                    staticStyle: { "margin-top": "20px" },
                                    attrs: { color: "primary" },
                                    nativeOn: {
                                      click: function ($event) {
                                        _vm.activeName = "second"
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "font-semibold pr-2 pl-2",
                                      },
                                      [
                                        _vm._v(
                                          "\n                Enviar endosso\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", [
                              _vm.BoletosEndosso.length > 0
                                ? _c("div", { staticClass: "upload-boletos" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "con-upload boletos_endosso",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "lista_boletos" },
                                          _vm._l(
                                            _vm.BoletosEndosso,
                                            function (boleto, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "item",
                                                },
                                                [
                                                  _c("div", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          boleto.Description
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "con-img-upload",
                                                    },
                                                    [
                                                      boleto.base64
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "img-upload",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "order-input",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      boleto.Number
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn-x-file",
                                                                  attrs: {
                                                                    type: "button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeFile(
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      staticClass:
                                                                        "material-icons notranslate",
                                                                      attrs: {
                                                                        translate:
                                                                          "no",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            clear\n                          "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "h4",
                                                                {
                                                                  staticClass:
                                                                    "text-archive",
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                    "font-size":
                                                                      "12px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      staticClass:
                                                                        "material-icons notranslate",
                                                                      attrs: {
                                                                        translate:
                                                                          "no",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            description\n                          "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("p", [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          boleto.Filename
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "con-input-upload",
                                                            },
                                                            [
                                                              _c("input", {
                                                                attrs: {
                                                                  "data-v-480def7c":
                                                                    "",
                                                                  type: "file",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.getFiles(
                                                                        $event,
                                                                        boleto,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-input",
                                                                  attrs: {
                                                                    "data-v-480def7c":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          Clique aqui para selecionar o arquivo. "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", {
                                                                staticClass:
                                                                  "input-progress",
                                                                staticStyle: {
                                                                  width: "0%",
                                                                },
                                                                attrs: {
                                                                  "data-v-480def7c":
                                                                    "",
                                                                },
                                                              }),
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn-upload-all vs-upload--button-upload",
                                                                  attrs: {
                                                                    "data-v-480def7c":
                                                                      "",
                                                                    disabled:
                                                                      "disabled",
                                                                    type: "button",
                                                                    title:
                                                                      "Upload",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      staticClass:
                                                                        "material-icons notranslate",
                                                                      attrs: {
                                                                        "data-v-480def7c":
                                                                          "",
                                                                        translate:
                                                                          "translate",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            cloud_upload\n                          "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                  _c("el-divider"),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c("el-alert", {
                                          attrs: {
                                            title:
                                              "Somente arquivo do tipo: pdf.",
                                            type: "warning",
                                          },
                                        }),
                                        _c("br"),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-right mt-5 w-full",
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass:
                                                  "button-default mr-5",
                                                staticStyle: {
                                                  "margin-right": "5px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.modalEndosso = false
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    Cancelar\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm.validaBoletos
                                              ? _c(
                                                  "vs-button",
                                                  {
                                                    attrs: { color: "primary" },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.uploadBoletos()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-semibold pr-2 pl-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Enviar Boletos\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "vs-button",
                                                  {
                                                    attrs: {
                                                      disabled: "",
                                                      color: "primary",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-semibold pr-2 pl-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Enviar Boletos"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _c("div", { staticClass: "text-center" }, [
                                    _c("h4", [
                                      _vm._v(
                                        "Nenhum Boleto disponível no momento."
                                      ),
                                    ]),
                                  ]),
                            ]),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "popup-base",
        {
          staticClass: "success-modal onpoint-successmodal",
          attrs: {
            showModal: _vm.modalUploadSucess,
            size: "sm",
            title: " ",
            "button-close-hidden": false,
          },
          on: {
            close: function ($event) {
              return _vm.reloadUploadModal()
            },
          },
        },
        [
          _c("template", { slot: "content" }, [
            _c(
              "div",
              { staticClass: "flex flex-col items-center pt-4" },
              [
                _c(
                  "h2",
                  {
                    staticClass: "flex flex-col items-center text-center w-1/2",
                  },
                  [
                    _c("vs-icon", {
                      staticClass: "mb-6",
                      attrs: { color: "success", icon: "check_circle" },
                    }),
                    _vm._v("\n          Sucesso\n        "),
                  ],
                  1
                ),
                _c("p", [
                  _vm._v("\n          Apólice enviada com sucesso!\n        "),
                ]),
                _vm._t("default"),
              ],
              2
            ),
          ]),
        ],
        2
      ),
      _c(
        "popup-base",
        {
          staticClass: "success-modal onpoint-successmodal",
          attrs: {
            showModal: _vm.modalcancelationReason,
            size: "sm",
            title: " ",
            "button-close-hidden": false,
          },
          on: {
            close: function ($event) {
              return _vm.closeModalRenovacao()
            },
          },
        },
        [
          _c("template", { slot: "content" }, [
            _c(
              "div",
              [
                _c("span", { staticClass: "mb-2" }, [
                  _vm._v("Selecione o motivo para o cancelamento\n          "),
                  _c("strong", { staticStyle: { color: "red" } }, [
                    _vm._v("*"),
                  ]),
                ]),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "escolha o motivo" },
                    model: {
                      value: _vm.cancelationReason,
                      callback: function ($$v) {
                        _vm.cancelationReason = $$v
                      },
                      expression: "cancelationReason",
                    },
                  },
                  _vm._l(_vm.cancelationReasonList, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "text-right mt-5 w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    staticStyle: { "margin-right": "5px" },
                    on: {
                      click: function ($event) {
                        return _vm.closeModalRenovacao()
                      },
                    },
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _vm.cancelationReason
                  ? _c(
                      "vs-button",
                      {
                        attrs: { color: "danger" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.disclamerCancelRenovacao()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v("\n            Cancelar proposta\n          "),
                        ]),
                      ]
                    )
                  : _c(
                      "vs-button",
                      { attrs: { disabled: "", color: "danger" } },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v(" Cancelar proposta"),
                        ]),
                      ]
                    ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto text-center mt-32",
      },
      [
        _c("p", [
          _c("span", { staticClass: "material-icons text-6xl" }, [
            _vm._v("description"),
          ]),
        ]),
        _c("p", [_vm._v("Você ainda não possui nenhuma proposta.")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }