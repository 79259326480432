<template>
  <section class="lista-proposta">
    <div class="m-1 mr-4 px-0 proposta">
      <breadcrumb title="Propostas" actualPage="Lista de propostas" />
    </div>

    <div
      class="grid grid-cols-3 mt-8 mb-6 gap-3 w-full md:w-4/5 lg:w-3/5 lp:w-1/2 "
    >
      <filtro
        ref="filtro"
        :titleName="'Propostas'"
        :isFilter="filtro"
        class="pesquisa"
      >
        <div class="pt-2 pb-6 px-4">
          <vs-row class="pt-0">
            <!-- <h5 class="title-group pt-0 pb-2">Dados da proposta</h5> -->
            <vs-col vs-lg="6" vs-sm="12">
              <vs-input
                size="large"
                label="Número da proposta"
                v-model="filterValue.number"
              />
            </vs-col>
            <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12">
              <p class="label-content label">Status da proposta</p>
              <v-select
                placeholder="Selecionar"
                class="w-full"
                :show-no-options="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="selectedProposalStatusOption"
                @input="setProposalStatusOption(selectedProposalStatusOption)"
                label="name"
                :options="proposalStatusOptionsInsurerMixin"
                size="large"
              >
              </v-select>
            </vs-col>
          </vs-row>

          <vs-row>
            <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12">
              <p class="label-content label">Data inicial</p>
              <datepicker
                :language="language"
                format="dd/MM/yyyy"
                v-model="filterValue.startDate"
              ></datepicker>
            </vs-col>

            <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12">
              <p class="label-content label">Data final</p>
              <datepicker
                :language="language"
                format="dd/MM/yyyy"
                v-model="filterValue.endDate"
              ></datepicker>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12"
              ><p class="label-content label" style="margin-left: -12px;">
                Tipo de proposta:
              </p>
            </vs-col>
            <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12"
              ><p class="label-content label" style="margin-left: -12px;">
                Tipo endosso:
              </p>
            </vs-col>
            <vs-col class="flex justify-between  pt-2" vs-lg="6" size="small">
              <el-select
                v-model="filterValue.proposalType"
                placeholder="Selecione"
                @change="typeChange()"
              >
                <el-option
                  v-for="item in typesProposal"
                  :key="item.Id"
                  :label="item.label"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </vs-col>
            <vs-col class="flex justify-between  pt-2" vs-lg="6" size="small">
              <el-select
                v-model="filterValue.endossoType"
                placeholder="Selecione"
                @change="typeChangeEndosso()"
              >
                <el-option
                  v-for="item in subtiposEndosso"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </vs-col>
          </vs-row>

          <!-- <hr class="mt-6 opacity-25" />
          <vs-row>
            <h5 class="title-group pb-2">Dados da Apólice</h5>

          </vs-row> -->

          <hr class="mt-6 opacity-25" />
          <vs-row>
            <!-- <h5 class="title-group pb-2">Dados dos envolvidos</h5> -->
            <vs-col vs-lg="4" vs-sm="12">
              <vs-input
                size="large"
                label="Nome do tomador"
                v-model="filterValue.policyHolderName"
              />
            </vs-col>
            <vs-col vs-lg="4" vs-sm="12">
              <vs-input
                size="large"
                label="Documento do tomador"
                v-model="filterValue.policyHolderCpfCnpj"
              />
            </vs-col>
            <vs-col vs-lg="4" vs-sm="12">
              <vs-input
                size="large"
                label="Nome do corretor"
                v-model="filterValue.brokerName"
              />
            </vs-col>
          </vs-row>
          <hr class="mt-6 opacity-25" />
          <vs-row>
            <vs-col class="flex justify-between">
              <vs-checkbox v-model="filterValue.enableToBigRisk"
                >Grande risco
              </vs-checkbox>

              <vs-checkbox v-model="filterValue.enableToMonitoring"
                >Monitoramento
              </vs-checkbox>

              <vs-checkbox v-model="filterValue.enableToSteppin"
                >Retoma de objeto (Step-in)
              </vs-checkbox>
            </vs-col>
          </vs-row>

          <div class="flex flex-row-reverse mt-4 pt-4">
            <vs-button
              @click.native="filterProposals(true)"
              color="primary"
              type="filled"
              class="ml-4"
              >Aplicar filtro</vs-button
            >
            <vs-button
              color="primary"
              type="filled"
              icon="clear"
              @click.native="clearFilter()"
              >Limpar filtro</vs-button
            >
          </div>
        </div>
      </filtro>
    </div>

    <div class="table-content pt-2">
      <template v-if="proposals.length > 0">
        <vs-table
          class="table-list"
          :data="proposals || []"
          noDataText="Nenhuma proposta encontrada"
          :hoverFlat="true"
        >
          <template slot="thead">
            <vs-th></vs-th>
            <vs-th sort-key="Number" class="text-center">Proposta nº</vs-th>
            <vs-th sort-key="TypeName" class="text-center">Tipo</vs-th>
            <vs-th v-if="podeExibirCanal" sort-key="Channel" class="text-center"
              >Canal</vs-th
            >
            <vs-th sort-key="PolicyHolderName" class="text-center"
              >Tomador</vs-th
            >
            <vs-th sort-key="ModalityName" class="text-center"
              >Modalidade</vs-th
            >
            <vs-th sort-key="dataProposta" class="text-center"
              >Data da proposta</vs-th
            >
            <vs-th sort-key="is" class="text-center">IS</vs-th>
            <vs-th sort-key="valorPremio" class="text-center"
              >Valor Prêmio</vs-th
            >
            <vs-th sort-key="status" class="text-center">Status</vs-th>
            <vs-th sort-key="broker" class="text-center">Corretor</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="text-center">
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="btn-action-proposta"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu
                    class="btn-action-proposta-options w-2/5 md:w-1/3 lg:w-1/4 xl:w-1/5"
                  >
                    <vs-dropdown-item @click="goToProposal(data[indextr])">
                      <p class="text-base px-6 py-2">
                        <span>Visualizar proposta</span>
                      </p>
                    </vs-dropdown-item>

                    <!--  v-if="canDownloadMinute(data[indextr].StatusId)"  -->
                    <vs-dropdown-item
                      divider
                      @click="downloadMinuteClick(data[indextr].UniqueId)"
                    >
                      <p class="text-base px-6 py-2">
                        <span>{{
                          data[indextr].StatusId == 1
                            ? "Imprimir proposta"
                            : "Imprimir minuta"
                        }}</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="data[indextr].PolicyEndorsementReasonBaseId == 18"
                      @click="disclamerCancel(data[indextr])"
                    >
                      <p class="text-base px-6 py-2">
                        <span>Cancelar Endosso</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="data[indextr].TypeName === 'Renovação' && canCancelItem(data[indextr].StatusId)"
                      @click="cancelarRenovacao(data[indextr])"
                    >
                      <p class="text-base px-6 py-2">
                        <span>Cancelar proposta</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="
                        data[indextr].EnableManualPolicyEmission ||
                          data[indextr].EnableManualBilletEmission
                      "
                      @click="goToEndosso(data[indextr])"
                    >
                      <p class="text-base px-6 py-2">
                        <span>Emitir endosso manual</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
              <vs-td :data="data[indextr].Number" class="text-center w-1/12">
                <div class="flex">
                  <b>{{ data[indextr].Number }}</b>
                  <vx-tooltip
                    v-if="data[indextr].StatusId === 6"
                    text="Proposta ainda não se encontra para análise da seguradora. Verifique as pendências para seguir com o processo de emissão"
                    position="top"
                  >
                    <i
                      class="material-icons alert text-xl ml-1 warning"
                      style="#ff9c07"
                      >info</i
                    >
                  </vx-tooltip>
                </div>
              </vs-td>

              <vs-td :data="data[indextr].TypeId" class="text-center w-1/12">
                <!-- <b>{{ data[indextr].TypeId == 1 ? "Proposta" : "Endosso" }}</b>  -->
                <b>{{ data[indextr].TypeName }}</b>
              </vs-td>

              <vs-td
                v-if="podeExibirCanal"
                :data="data[indextr].NomeTipoCanal"
                class="text-center w-1/4"
              >
                <b>{{
                  data[indextr].NomeTipoCanal
                    ? data[indextr].NomeTipoCanal
                    : "Canal não definido"
                }}</b>
              </vs-td>

              <vs-td
                :data="data[indextr].PolicyHolderCpfCnpj"
                class="text-center w-1/5"
              >
                <b>{{ data[indextr].PolicyHolderName }}</b>
                <small class="block">
                  {{ data[indextr].PolicyHolderCpfCnpj | maskCpfCnpj }}
                </small>
              </vs-td>

              <vs-td
                :data="data[indextr].ModalityName"
                class="text-center w-1/12"
              >
                <b>{{ data[indextr].ModalityName }}</b>
              </vs-td>

              <vs-td
                :data="data[indextr].CreateDate"
                class="text-center w-1/12 date_color"
              >
                <b>{{
                  new Date(data[indextr].CreateDate) | dateFormat("DD/MM/YYYY")
                }}</b>
              </vs-td>

              <vs-td
                :data="data[indextr].InsuredAmountValue"
                class="text-center w-1/12"
              >
                <currency-input
                  class="input-currency font-semibold text-center"
                  v-model="data[indextr].InsuredAmountValue"
                  disabled="true"
                />
              </vs-td>

              <vs-td
                :data="data[indextr].InsurancePremium"
                class="text-center w-1/12"
              >
                <currency-input
                  v-model="data[indextr].InsurancePremium"
                  class="input-currency font-semibold text-center"
                  disabled="true"
                />
              </vs-td>

              <vs-td
                :data="data[indextr].StatusName"
                class="text-center w-1/12"
              >
                <vs-button
                  class="status"
                  type="filled"
                  size="small"
                  :color="
                    data[indextr].StatusId
                      | setColorStatus(data[indextr].TimelineStatusId)
                  "
                >
                  {{ data[indextr].StatusId | setLabelStatus }}
                </vs-button>
              </vs-td>
              <vs-td
                :data="data[indextr].BrokerName"
                class="text-center w-1/12"
              >
                <b>{{ data[indextr].BrokerName }}</b>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          class="mt-5"
          :total="numberOfPages"
          v-model="currentPage"
        >
        </vs-pagination>
      </template>
      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto text-center mt-32">
          <p><span class="material-icons text-6xl">description</span></p>
          <p>Você ainda não possui nenhuma proposta.</p>
        </div>
      </template>
    </div>

    <vs-popup
      class="termos-modal fix-scroll"
      :button-close-hidden="true"
      :title="'Endosso manual proposta N°: ' + uploadData.ProposalNumber"
      :active.sync="modalEndosso"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="Dados Gerais" name="first" class="text-center">
          <div role="alert" class="el-alert el-alert--warning is-light">
            <div class="el-alert__content">
              <p class="el-alert__description">
                Para prosseguir com a emissão do endosso manual, será necessário
                incluir os documentos do endosso e os boletos correspondentes.
                Certifique-se de que todos os documentos e informações
                necessários estejam corretos e completos antes de dar
                continuidade ao processo.
                <a href="#" @click.prevent="activeName = 'second'"
                  >Prosseguir</a
                >
              </p>
            </div>
          </div>
          <br />
          <vs-button color="primary" @click="disclamerforcarAprovManual()">
            <span class="font-semibold pr-2 pl-2">
              Forçar Emitir Endosso
            </span>
          </vs-button>
          <vs-row>
            <vs-col :vs-lg="6" vs-sm="12">
              <p class="flex font-semibold">N° Documento Segurado</p>
              <vs-input
                disabled
                class="w-full"
                v-model="uploadData.InsuredCpfCnpj"
                size="large"
              />
            </vs-col>
            <vs-col :vs-lg="6" vs-sm="12">
              <p class="flex font-semibold">Segurado</p>
              <vs-input
                disabled
                class="w-full"
                v-model="uploadData.ProposalNumber"
                size="large"
              />
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col :vs-lg="6" vs-sm="12">
              <p class="flex font-semibold">N° Documento Tomador</p>
              <vs-input
                disabled
                class="w-full"
                v-model="uploadData.PolicyHolderCpfCnpj"
                size="large"
              />
            </vs-col>

            <vs-col :vs-lg="6" vs-sm="12">
              <p class="flex font-semibold">Tomador</p>
              <vs-input
                disabled
                class="w-full"
                v-model="uploadData.PolicyHolderName"
                size="large"
              />
            </vs-col>
          </vs-row>

          <div class="vw-row mt-3">
            <div class="vx-col w-full text-right">
              <vs-button
                @click="modalEndosso = false"
                class="button-default mr-5"
              >
                Cancelar
              </vs-button>
            </div>
          </div></el-tab-pane
        >
        <el-tab-pane label="Upload Endosso" name="second">
          <div
            v-if="uploadData.IsPolicyDocumentSent"
            style="    margin: 20px 0px;"
          >
            <el-alert
              title="Apólice enviada com sucesso!"
              type="success"
              :closable="false"
              show-icon
            >
            </el-alert>
            <div class="vw-row mt-3">
              <div class="vx-col w-full text-right">
                <vs-button
                  style="margin-right: 5px"
                  @click="modalEndosso = false"
                  class="button-default mr-5"
                >
                  Cancelar
                </vs-button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="con-img-upload">
              <div class="img-upload" v-if="apolice.base64">
                <button
                  class="btn-x-file"
                  type="button"
                  @click="removeFileApolice()"
                >
                  <i translate="no" class="material-icons notranslate">
                    clear
                  </i>
                </button>

                <h4
                  class="text-archive"
                  style="width:100%;     font-size: 12px;"
                >
                  <i translate="no" class="material-icons notranslate">
                    description
                  </i>
                  <p>
                    <b>{{ apolice.Filename }}</b>
                  </p>
                </h4>
              </div>
              <div class="con-input-upload" v-else>
                <input
                  data-v-480def7c=""
                  type="file"
                  @change="getFilesApolice($event)"
                /><span data-v-480def7c="" class="text-input">
                  Clique aqui para selecionar o arquivo. </span
                ><span
                  data-v-480def7c=""
                  class="input-progress"
                  style="width: 0%;"
                ></span
                ><button
                  data-v-480def7c=""
                  disabled="disabled"
                  type="button"
                  title="Upload"
                  class="btn-upload-all vs-upload--button-upload"
                >
                  <i
                    data-v-480def7c=""
                    translate="translate"
                    class="material-icons notranslate"
                  >
                    cloud_upload
                  </i>
                </button>
              </div>
            </div>

            <div class="vw-row mt-3">
              <div class="vx-col w-full text-right">
                <vs-button
                  style="margin-right: 5px"
                  @click="modalEndosso = false"
                  class="button-default mr-5"
                >
                  Cancelar
                </vs-button>
                <vs-button
                  @click.native="disclamerEnviarApolice()"
                  color="primary"
                  v-if="apolice.base64"
                >
                  <span class="font-semibold pr-2 pl-2">
                    Enviar endosso
                  </span>
                </vs-button>
                <vs-button disabled color="primary" v-else>
                  <span class="font-semibold pr-2 pl-2">
                    Enviar endosso
                  </span>
                </vs-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Upload Boletos" name="third">
          <div
            v-if="uploadData.IsInstallmentsSent"
            style="    margin: 20px 0px;"
          >
            <el-alert
              v-if="
                uploadData.IsInstallmentsSent &&
                  uploadData.Installments.length > 0
              "
              title="Boletos enviados com sucesso!"
              type="success"
              :closable="false"
              show-icon
            >
            </el-alert>
            <div v-else class="text-center">
              <h4>Nenhum Boleto disponível no momento.</h4>
            </div>

            <div class="vw-row mt-3">
              <div class="vx-col w-full text-right">
                <vs-button
                  style="margin-right: 5px"
                  @click="modalEndosso = false"
                  class="button-default mr-5"
                >
                  Cancelar
                </vs-button>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              v-if="uploadData.IsPolicyDocumentSent == false"
              class="text-center"
            >
              <el-alert
                title="Atenção!"
                type="warning"
                description="Para Prosseguir com envio dos boletos é necessário o envio da apólice previamente!"
              >
              </el-alert>
              <vs-button
                style="margin-top: 20px"
                @click.native="activeName = 'second'"
                color="primary"
              >
                <span class="font-semibold pr-2 pl-2">
                  Enviar endosso
                </span>
              </vs-button>
            </div>
            <div v-else>
              <div class="upload-boletos" v-if="BoletosEndosso.length > 0">
                <div class="con-upload boletos_endosso">
                  <div class="lista_boletos">
                    <div
                      class="item"
                      v-for="(boleto, index) in BoletosEndosso"
                      :key="index"
                    >
                      <div>
                        <b>{{ boleto.Description }}</b>
                      </div>
                      <div class="con-img-upload">
                        <div class="img-upload" v-if="boleto.base64">
                          <div class="order-input">{{ boleto.Number }}</div>
                          <button
                            class="btn-x-file"
                            type="button"
                            @click="removeFile(index)"
                          >
                            <i
                              translate="no"
                              class="material-icons notranslate"
                            >
                              clear
                            </i>
                          </button>

                          <h4
                            class="text-archive"
                            style="width:100%;     font-size: 12px;"
                          >
                            <i
                              translate="no"
                              class="material-icons notranslate"
                            >
                              description
                            </i>
                            <p>
                              <b>{{ boleto.Filename }}</b>
                            </p>
                          </h4>
                        </div>
                        <div class="con-input-upload" v-else>
                          <input
                            data-v-480def7c=""
                            type="file"
                            @change="getFiles($event, boleto, index)"
                          /><span data-v-480def7c="" class="text-input">
                            Clique aqui para selecionar o arquivo. </span
                          ><span
                            data-v-480def7c=""
                            class="input-progress"
                            style="width: 0%;"
                          ></span
                          ><button
                            data-v-480def7c=""
                            disabled="disabled"
                            type="button"
                            title="Upload"
                            class="btn-upload-all vs-upload--button-upload"
                          >
                            <i
                              data-v-480def7c=""
                              translate="translate"
                              class="material-icons notranslate"
                            >
                              cloud_upload
                            </i>
                          </button>
                        </div>
                      </div>

                      <el-divider></el-divider>
                    </div>
                  </div>

                  <el-alert
                    title="Somente arquivo do tipo: pdf."
                    type="warning"
                  >
                  </el-alert>
                  <br />

                  <div class="text-right mt-5 w-full">
                    <vs-button
                      style="margin-right: 5px"
                      @click="modalEndosso = false"
                      class="button-default mr-5"
                    >
                      Cancelar
                    </vs-button>
                    <vs-button
                      @click.native="uploadBoletos()"
                      color="primary"
                      v-if="validaBoletos"
                    >
                      <span class="font-semibold pr-2 pl-2">
                        Enviar Boletos
                      </span>
                    </vs-button>
                    <vs-button disabled color="primary" v-else>
                      <span class="font-semibold pr-2 pl-2">
                        Enviar Boletos</span
                      >
                    </vs-button>
                  </div>
                </div>
              </div>
              <div v-else class="text-center">
                <h4>Nenhum Boleto disponível no momento.</h4>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </vs-popup>
    <popup-base
      @close="reloadUploadModal()"
      :showModal="modalUploadSucess"
      size="sm"
      class="success-modal onpoint-successmodal"
      title=" "
      :button-close-hidden="false"
    >
      <template slot="content">
        <div class="flex flex-col items-center pt-4">
          <h2 class="flex flex-col items-center text-center w-1/2">
            <vs-icon color="success" class="mb-6" icon="check_circle"></vs-icon>
            Sucesso
          </h2>
          <p>
            Apólice enviada com sucesso!
          </p>
          <slot></slot>
        </div>
      </template>
    </popup-base>
    <popup-base
      @close="closeModalRenovacao()"
      :showModal="modalcancelationReason"
      size="sm"
      class="success-modal onpoint-successmodal"
      title=" "
      :button-close-hidden="false"
    >
      <template slot="content">
        <div>
          <span class="mb-2"
            >Selecione o motivo para o cancelamento
            <strong style="color:red">*</strong>
          </span>
          <el-select v-model="cancelationReason" placeholder="escolha o motivo">
            <el-option
              v-for="item in cancelationReasonList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </template>

      <template slot="footer">
        <div class="text-right mt-5  w-full">
          <vs-button
            style="margin-right: 5px"
            @click="closeModalRenovacao()"
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>
          <vs-button
            @click.native="disclamerCancelRenovacao()"
            color="danger"
            v-if="cancelationReason"
          >
            <span class="font-semibold pr-2 pl-2">
              Cancelar proposta
            </span>
          </vs-button>
          <vs-button disabled color="danger" v-else>
            <span class="font-semibold pr-2 pl-2"> Cancelar proposta</span>
          </vs-button>
        </div>
      </template>
    </popup-base>
  </section>
</template>

<script>
import Filtro from "@/components/Filtro/Filtro.vue";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import LocationDropdown from "@/components/location-dropdown/LocationDropdown";
import { TheMask } from "vue-the-mask";
import optionsStaticMixin from "@/mixins/optionsStaticMixin";
import * as Utils from "@/utils/utils";
import * as Enums from "@/utils/enums";

import axiosInstance from "@/axios";
import { mapActions } from "vuex";

import * as Permissoes from "@/utils/permissions.js";
import mapfreProvider from "@/providers/mapfre-provider.js";

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = error => reject(error);
  });
};
export default {
  name: "lista-propostas-seguradora",
  components: {
    Datepicker,
    Filtro,
    Breadcrumb,
    TheMask,
    LocationDropdown
  },
  mixins: [optionsStaticMixin],
  data() {
    return {
      ProposalTimelineStatusId: null,
      validaBoletos: false,
      apolice: {
        Filename: null,
        FileExtension: null,
        base64: null
      },
      BoletosEndosso: [],
      uploadData: "",
      mockup: [
        {
          ProposalInstallmentId: 173648,
          CreateDate: "2023-08-08T10:35:27.247",
          Number: 1,
          Description: "1ª de $1,807.71",
          DueDate: "2023-08-15T10:35:27.247",
          BankSlipArchiveId: 0,
          BankSlipArchiveUniqueId: "00000000-0000-0000-0000-000000000000"
        }
      ],
      activeName: "first",
      HasManualEndorsementEmission: false,
      filtro: false,
      proposals: [],
      language: ptBR,
      totalItems: 0,
      currentPage: 1,
      rowsPerPage: 10,
      numberOfPages: 0,
      selectedProposalStatusOption: undefined,
      filterValue: {
        number: "",
        statusId: "",
        policyHolderName: "",
        policyHolderCpfCnpj: "",
        startDate: "",
        endDate: "",
        brokerName: "",
        endossoType: null
      },
      subtiposEndosso: false,
      modalDocuments: false,
      modalEndosso: false,
      endosso: false,
      texto:
        "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Why do we use it? It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",

      typesProposal: [
        {
          label: "Proposta",
          Id: 1
        },
        {
          label: "Endosso",
          Id: 2
        },
        {
          label: "Renovação",
          Id: 4
        }
      ],
      permissoesCorretorMapfre: [],
      modalUploadSucess: false,
      modalcancelationReason: false,
      selectProposalRenovacao: false,
      cancelationReasonList: [
        "Fim do Processo",
        "Transito Julgado",
        "Extinção do Processo",
        "Substituição de apólice por outra de garantia",
        "Substituição de apólice por outra de Outra congênere"
      ],
      cancelationReason: null
    };
  },

  watch: {
    currentPage(newVal, oldVal) {
      this.filterProposals();
    }
  },
  async mounted() {
    this.carregarCorretora();
    await this.$onpoint.loading(async () => {
      return await this.pegarPermissoesMapfre();
    });
  },
  created() {
    this.ObterSubtiposEndosso();
    this.$appInsights.trackPageView({
      name: "propostas-seguradora",
      url: window.location.href
    });
  },
  computed: {
    podeExibirCanal() {
      return this.permissoesCorretorMapfre.includes("ExibirCanal");
    }
  },
  methods: {
    ...mapActions("propostas-module", ["gerarMinuta"]),
    ...mapActions("endosso-module", ["getCompareChanges"]),
    ...mapActions("proposta-module", [
      "limparEstado",
      "loadEndorsementInfoByUniqueId"
    ]),
      canCancelItem(proposalStatusId) {
      return (
        proposalStatusId != Enums.ProposalStatus.Emitida &&
        proposalStatusId != Enums.ProposalStatus.Recusada &&
        proposalStatusId != Enums.ProposalStatus.Cancelada
      );
    },
    closeModalRenovacao(){
      this.modalcancelationReason = false; 
      this.cancelationReason = null;
    },
    disclamerCancel(proposal) {
      this.$confirm(
        "Essa ação tem efeito permanente, deseja continuar?",
        "Aviso!",
        {
          confirmButtonText: "Confirmar",
          cancelButtonText: "Voltar",
          type: "warning"
        }
      ).then(() => {
        this.cancelProposal(proposal, false);
      });
    },
    disclamerCancelRenovacao() {
      this.$confirm(
        "Essa ação tem efeito permanente, deseja continuar?",
        "Aviso!",
        {
          confirmButtonText: "Confirmar",
          cancelButtonText: "Voltar",
          type: "warning"
        }
      ).then(() => {
        this.cancelProposal(this.selectProposalRenovacao, true);
      });
    },
   async cancelProposal(proposal, renovacao) {
      let payload = {
        ProposalUniqueId: proposal.UniqueId,
        CancelReasonProposal: renovacao ? this.cancelationReason : null
      };
     await this.$onpoint.loadingModal("Cancelando a proposta...", async () => {
        return await axiosInstance
          .post("api/Proposal/CancelProposal", payload)
          .then(result => {
            this.$notify({
              title: "Success",
              message: "Solicitação de cancelamento enviada com sucesso!",
              type: "success"
            });
            this.modalcancelationReason = false;
            this.cancelationReason = null;
            return this.filterProposals();
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    typeChange() {
      if (this.filterValue.proposalType === 1) {
        this.filterValue.endossoType = null;
      }
      this.$forceUpdate();
    },
    typeChangeEndosso() {
      this.filterValue.proposalType = 2;
      this.$forceUpdate();
    },
    veriryFile(name) {
      let toLowerCase = name.toLowerCase();
      let extension = toLowerCase.split(".").pop();
      console.log("arquivo: ", extension);
      let types = ["pdf"];
      let result = null;
      return types.includes(extension);
    },
    removeFile(index) {
      this.BoletosEndosso[index].base64 = null;
      this.BoletosEndosso[index].FileExtension = null;
      this.BoletosEndosso[index].Filename = null;
      setTimeout(() => {
        this.validaBoletosEndosso();
      }, 1000);
    },
    removeFileApolice(index) {
      this.apolice.base64 = null;
      this.apolice.FileExtension = null;
      this.apolice.Filename = null;
    },

    disclamerEnviarApolice() {
      this.$confirm(
        "Tem certeza que deseja enviar este documento? Essa apólice será emitida e não será possível a sua reversão.",
        "Atenção!",
        {
          type: "warning",
          confirmButtonText: "Continuar",
          cancelButtonText: "Voltar",
          center: true
        }
      )
        .then(() => {
          this.uploadApolice();
        })
        .catch(() => {
          console.log("cancelou");
        });
    },
    disclamerforcarAprovManual() {
      this.$confirm(
        "Tem certeza de que deseja emitir o endosso? A apólice será cancelada, sendo que não será possível a sua reversão.",
        "Atenção!",
        {
          type: "warning",
          confirmButtonText: "Continuar",
          cancelButtonText: "Voltar",
          center: true
        }
      )
        .then(() => {
          this.forcarAprovManual();
        })
        .catch(() => {
          console.log("cancelou");
        });
    },
    forcarAprovManual() {
      this.$vs.loading();

      let data = {
        ProposalUniqueId: this.uploadData.ProposalUniqueId,
        UseFixedDocument: true
      };
      this.$onpoint.loading(() => {
        return axiosInstance
          .post(`/api/Proposal/ManualUploadIssuanceEndorsementPolicy`, data)
          .then(response => {
            console.log(response);
            this.$onpoint.successModal("Apólice enviada com sucesso!");
            this.modalEndosso = false;
            this.$vs.loading.close();
          })
          .catch(errors => {
            this.$vs.loading.close();
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    },
    ObterSubtiposEndosso() {
      return axiosInstance
        .get(`/api/Endorsement/ObterSubtiposEndosso`)
        .then(response => {
          this.subtiposEndosso = response.data.Response;
        })
        .catch(errors => {
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    },
    reloadUploadModal() {
      this.modalUploadSucess = false;
      this.goToEndosso({ UniqueId: this.uploadData.ProposalUniqueId });
      this.activeName = "second";
    },
    async uploadApolice() {
      this.$vs.loading();
      let data = {
        ProposalUniqueId: this.uploadData.ProposalUniqueId,
        DocumentFiles: [
          {
            Filename: this.apolice.Filename,
            FileExtension: this.apolice.FileExtension,
            Base64Data: this.apolice.base64
          }
        ]
      };
      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .post(`/api/Proposal/ManualUploadIssuanceEndorsementPolicy`, data)
          .then(response => {
            this.modalUploadSucess = true;
            this.modalEndosso = false;
            this.apolice.Filename = null;
            this.apolice.FileExtension = null;
            this.apolice.base64 = null;
            this.$vs.loading.close();
          })
          .catch(errors => {
            this.$vs.loading.close();
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    },
    uploadBoletos() {
      this.$vs.loading();
      let Array = [];
      let dados = "";
      for (let i = 0; i < this.BoletosEndosso.length; i++) {
        dados = {
          Filename: this.BoletosEndosso[i].Filename,
          FileExtension: this.BoletosEndosso[i].FileExtension,
          Order: this.BoletosEndosso[i].Number,
          Base64Data: this.BoletosEndosso[i].base64
        };
        Array.push(dados);
      }
      let data = {
        ProposalUniqueId: this.uploadData.ProposalUniqueId,
        DocumentFiles: Array
      };
      this.$onpoint.loading(() => {
        return axiosInstance
          .post(`/api/Proposal/ManualUploadIssuanceBillet`, data)
          .then(response => {
            console.log(response);
            this.$onpoint.successModal("Boletos enviados com sucesso!");
            this.modalEndosso = false;
            this.filterProposals();
            this.$vs.loading.close();
          })
          .catch(errors => {
            this.$vs.loading.close();
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    },
    validaBoletosEndosso() {
      let isValid = true;
      let dados = "";
      if (this.BoletosEndosso.length > 0) {
        for (let i = 0; i < this.BoletosEndosso.length; i++) {
          dados = this.BoletosEndosso[i];
          console.log("validaBoletosMap2", dados, dados.base64);
          if (dados.base64) {
            isValid = true;
          } else {
            isValid = false;
          }
          this.validaBoletos = isValid;
        }
      } else {
        this.validaBoletos = false;
      }
    },
    getFiles(e, boleto, index) {
      this.$vs.loading();
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
      let fileSize = e.target.files[0].size;
      let fileMb = fileSize / 1024 ** 2;
      if (fileMb > 25) {
        this.$onpoint.errorModal(
          "Arquivo muito grande, escolha arquivos até 25mb e tente novamente!"
        );
      } else {
        if (this.veriryFile(e.target.files[0].name)) {
          getBase64(e.target.files[0]).then(result => {
            (this.BoletosEndosso[index].Filename = e.target.files[0].name.split(
              "."
            )[0]),
              (this.BoletosEndosso[
                index
              ].FileExtension = e.target.files[0].name.split(".").pop()),
              (this.BoletosEndosso[index].base64 = result);
          });
        } else {
          this.$onpoint.errorModal("Arquivo inválido, tente novamente!");
        }
      }
      setTimeout(() => {
        this.validaBoletosEndosso();
      }, 1000);
    },
    getFilesApolice(e) {
      this.$vs.loading();
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
      let fileSize = e.target.files[0].size;
      let fileMb = fileSize / 1024 ** 2;
      if (fileMb > 25) {
        this.$onpoint.errorModal(
          "Arquivo muito grande, escolha arquivos até 25mb e tente novamente!"
        );
      } else {
        if (this.veriryFile(e.target.files[0].name)) {
          getBase64(e.target.files[0]).then(result => {
            (this.apolice.Filename = e.target.files[0].name.split(".")[0]),
              (this.apolice.FileExtension = e.target.files[0].name
                .split(".")
                .pop()),
              (this.apolice.base64 = result);
          });
        } else {
          this.$onpoint.errorModal("Arquivo inválido, tente novamente!");
        }
      }
    },

    carregarCorretora() {
      return this.loadProposals();
    },

    setProposalStatusOption(option) {
      if (option) {
        this.ProposalTimelineStatusId = option.ProposalTimelineStatusId;
        this.filterValue.statusId = option.typeId;
      } else {
        this.ProposalTimelineStatusId = null;
      }
    },

    async downloadMinuteClick(proposalUniqueId) {
      await this.$onpoint.loading(async () => {
        return await this.gerarMinuta(proposalUniqueId)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    goToProposal(proposal) {
      this.$router.push({
        name: "resumo-proposta-visao-seguradora",
        params: {
          propostaUniqueId: proposal.UniqueId,
          proposalType: proposal.TypeName
        }
      });
    },
    callBackUpload() {},
    closeModal() {},
    goToEndosso(proposal) {
      // this.loadPropostaUniqueId(proposal.UniqueId)
      this.uploadData = "";
      this.activeName = "first";
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `/api/Policy/GetEmissionUploadDocumentStatus?ProposalUniqueId=${proposal.UniqueId}`
          )
          .then(response => {
            if (response.data.Response.Installments.length > 0) {
              let array = [];
              let dados = "";
              this.uploadData = response.data.Response;
              response.data.Response.Installments.map(item => {
                dados = {
                  Number: item.Number,
                  Description: item.Description,
                  Filename: "",
                  FileExtension: "",
                  base64: null
                };
                array.push(dados);
              });
              this.BoletosEndosso = array;
            } else {
              this.uploadData = response.data.Response;
            }
            this.modalEndosso = true;
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
    loadPropostaUniqueId(UniqueIdId) {
      return this.$onpoint.loading(async () => {
        try {
          await this.loadEndorsementInfoByUniqueId(UniqueIdId).then(data => {
            this.endosso = data;
            this.modalEndosso = true;
            // this.loadGetCompareChanges();
          });
        } catch (error) {
          console.log("erro");
        }
      });
    },

    async loadGetCompareChanges() {
      if (this.proposta.ProposalTypeId == 2)
        await this.$onpoint.loading( async () => {
          return await this.getCompareChanges({
            uniqueId: this.$route.params.propostaUniqueId
              ? this.$route.params.propostaUniqueId
              : this.$route.params.proposalType
          }).then(result => {
            this.responsavelDataUpdateEndosso = {
              UserName: result.UserOwner.Name,
              ModifiedAt: result.ModifiedAt
            };

            ((result || {}).ChangeTypes || []).forEach(item => {
              item.ChangesValues.forEach(element => {
                this.diffEndosso.push({
                  current: element.Current ? element.Current : "--",
                  origin: element.Previous ? element.Previous : "--",
                  label: element.Name,
                  key: element.Name + element.Current
                });
              });
            });
          });
        });
    },
    clearFilter() {
      this.ProposalTimelineStatusId = null;
      this.selectedProposalStatusOption = undefined;
      this.filterValue = {};
      this.currentPage = 1;
      this.filterProposals();
    },

    filterProposals(setCurrentPage) {
      this.filtro = !this.filtro;
      if (setCurrentPage) this.currentPage = 1;

      this.loadProposals(
        this.filterValue.number,
        this.filterValue.policyHolderName,
        this.filterValue.statusId,
        this.filterValue.policyHolderCpfCnpj
          ? this.$utils.removeSpecialChars(this.filterValue.policyHolderCpfCnpj)
          : this.filterValue.policyHolderCpfCnpj,
        this.filterValue.startDate,
        this.filterValue.endDate,
        this.filterValue.brokerName,
        this.filterValue.proposalType || "",
        this.filterValue.endossoType || "",
        this.filterValue.enableToBigRisk,
        this.filterValue.enableToMonitoring,
        this.filterValue.enableToSteppin
      );
    },

    loadProposals(
      number = "",
      policyHolderName = "",
      statusId = "",
      policyHolderCpfCnpj = "",
      startDate = "",
      endDate = "",
      brokerName = "",
      proposalType = "",
      endossoType = "",
      enableToBigRisk = false,
      enableToMonitoring = false,
      enableToSteppin = false
    ) {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `api/Proposal/SearchProposalsByInsuranceCompany?Number=${number}&PolicyHolderName=${policyHolderName}&StatusId=${statusId}&PolicyHolderCpfCnpj=${policyHolderCpfCnpj}&StartDate=${Utils.dateToDDMMYYYY(
              startDate
            )}&EndDate=${Utils.dateToDDMMYYYY(endDate)}&currentPage=${
              this.currentPage
            }&rowsPerPage=${
              this.rowsPerPage
            }&ProposalType=${proposalType}&PolicyEndorsementReasonBaseId=${endossoType}&BrokerName=${brokerName}&EnableToBigRisk=${enableToBigRisk}&EnableToMonitoring=${enableToMonitoring}&EnableToSteppin=${enableToSteppin}&ProposalTimelineStatusId=${
              this.ProposalTimelineStatusId
            }`
          )
          .then(response => {
            response = response.data.Response;
            this.proposals = response.Data;
            this.HasManualEndorsementEmission =
              response.HasManualEndorsementEmission;
            this.totalItems = response.NumberOfRows;
            this.numberOfPages = response.NumberOfPages;
            this.limparEstado();
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
    canDownloadMinute(proposalStatusId) {
      return (
        proposalStatusId != Enums.ProposalStatus.Emitida &&
        proposalStatusId != Enums.ProposalStatus.Recusada &&
        proposalStatusId != Enums.ProposalStatus.Cancelada
      );
    },

    async pegarPermissoesMapfre() {
      this.permissoesCorretorMapfre = [];
      try {
        this.permissoesCorretorMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.PROPOSTA);
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },
    cancelarRenovacao(proposta) {
      this.selectProposalRenovacao = proposta;
      this.modalcancelationReason = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";
.btn-action-proposta {
  width: 60px !important;
}
.con-vs-chip {
  min-width: 100px;
  min-height: 36px;
  border-radius: 12px;
  font-weight: 500;
}

.input-currency {
  border: none;
  background: transparent;
  color: inherit;
}

.lista-proposta .container {
  max-width: 99% !important;
}

.status {
  width: 11em !important;
  height: 35px;
  padding: 0.5em 1em !important;
  pointer-events: none;
}

b {
  font-weight: 600;
}

::v-deep .vs__dropdown-toggle {
  background: white;
  border: none;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 20px;
    i {
      font-size: 2em;
    }

    input {
      padding-left: 40px !important;
      font-size: 1.4em !important;
      max-width: 370px;
    }

    @include lg {
      margin-top: -64px;
    }
  }

  ::v-deep .vs-table-text {
    justify-content: center;
  }

  ::v-deep .vs-table--search-input {
    border: 0 !important;
    height: 47px;
    width: 300px;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }

  .btn-action-proposta {
    cursor: pointer;
    width: 135px;
  }
}

.btn-action-proposta-options {
  ::v-deep .vs-dropdown--menu {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  }
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>

<style lang="scss">
#filtro-open,
.filtro-open {
  .vs__dropdown-toggle {
    border: 1px solid rgba(60, 60, 60, 0.26);
    box-shadow: 0 0;

    &:focus-within {
      border: 1px solid rgba(var(--vs-primary), 1) !important;
    }
  }

  .vdp-datepicker input {
    height: 47px !important;

    &:focus {
      border: 1px solid rgba(var(--vs-primary), 1) !important;
    }
  }
}
.date_color {
  color: #9ea0a5;
}
</style>

<style lang="scss" scoped>
.con-img-upload {
  background: transparent;
  display: flex;
  align-content: center;
  justify-content: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.lista_boletos {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  .item {
    position: relative;
    text-align: center;
    padding-top: 10px;
    width: 49%;
    .space {
      margin-bottom: 25px;
    }
  }
}
</style>

<style lang="scss">
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: rgba(var(--vs-primary), 1) !important;
  border-color: rgba(var(--vs-primary), 1) !important;
}

.el-radio-button .is-active span:hover {
  color: rgba(var(--vs-primary), 1) !important;
}
.el-radio-button .is-active span {
  color: white !important;
}

.el-radio__input.is-checked + .el-radio__label {
  color: rgba(var(--vs-primary), 1) !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: rgba(var(--vs-primary), 1) !important;
  background: rgba(var(--vs-primary), 1) !important;
}

.vs-input--label {
  font-size: 15px !important;
}
.label-content {
  font-size: 16px !important;
}

#filtro-open .vs-popup {
  min-width: 1100px !important;
}
#filtro-open .el-radio {
  margin-bottom: 10px !important;
}
</style>
